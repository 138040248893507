// import Swiper JS
import Swiper, { Navigation, Pagination } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
let _homepage = document.body.classList.contains('home');
window.addEventListener('DOMContentLoaded', () => {
    // homepage slider
    const homepageHero = new Swiper('.hero-slider', {
        modules: [Navigation],
        loop: true,
        navigation: {
            nextEl: '.hero-button-next',
            prevEl: '.hero-button-prev',
        },
    });

    const homePosts = new Swiper('.home-posts', {
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 25,
        loop: false,
        pagination: {
            el: '.home-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            581: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1141: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    });

    if (_homepage) {
        homeSocialSlider();
    }

});

let homeSocialSlider = () => {
    var homeSocialSlider = {};
    var homeSocialSliderSettings = {
        modules: [Navigation],
        slidesPerView: 'auto',
        loop: true,
        // slidesPerView: 3,
        // spaceBetween: 80,
        navigation: {
            prevEl: '.homepage__social__item-prev',
            nextEl: '.homepage__social__item-next',
        },
    };

    homeSocialSlider = new Swiper('.homepage__social--slider', homeSocialSliderSettings);
}
